@import '~antd/dist/antd.css';
.site-layout-content {
  background: #fff;
  padding: 24px;
  min-height: 280px;
}
.logo {
  width: 44px;
  height: 48px;
  margin: 0 24px 0 0;
  float: left;
}
.desktop-left-navbar{
  float: left;
}
.desktop-search-box{
  float: left;
  margin-left: 100px;
  min-width: 350px;
  margin-top: -8px;
}
.desktop-right-navbar{
  float: right;
}
.profile {
  float: right;
}
.search-box {
  float: left;
}
  [data-theme="dark"] .site-layout-content {
    background: #141414;
  }
  [data-theme="dark"] .logo {
    background: rgba(255,255,255,0.3);
  }